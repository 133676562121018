import {
  ref,
  computed,
  watch,
  useRoute,
  useStore,
  useContext,
} from '@nuxtjs/composition-api';

import { checkMobile } from '@/utils/deviceCheck';

import useThroughBanner from '@/compositions/useThroughBanner';
import useHttpClient from '@/compositions/useHttpClient';

import getOptionsSetCookie from '@/utils/getOptionsSetCookie';

const useLayoutBlocks = () => {
  const route = useRoute();
  const store = useStore();
  const throughBanner = useThroughBanner();
  const { $cookies, $config } = useContext();
  const { user } = useHttpClient();

  const isMobile = checkMobile();

  const showCookies = ref(false);

  const isSaleDetailPage = computed({
    get: () => (route.value.name === 'sales-detail' && route.value.name === 'sales-detail-pages'),
    set: () => {},
  });

  const isMainPage = computed({
    get: () => (route.value.name === 'main'),
    set: () => {},
  });

  const showBanner = computed({
    get: () => {
      if (isMobile) {
        return !isMainPage.value && !isSaleDetailPage.value;
      }
      return true;
    },
    set: () => {},
  });

  const computeTypeBanner = computed({
    get: () => {
      if (!isMobile) {
        return 'TOP_LOCATION_DELIVERY';
      }
      switch (route.value.name) {
        case 'catalog':
          return 'MOBILE_CATALOG_PAGE';
        case 'catalog-category':
          return 'MOBILE_CATALOG_PAGE_BANNER';
        default:
          return 'HEADER_SALE_MOBILE';
      }
    },
    set: () => {},
  });

  const showFooter = computed({
    get: () => {
      if (isMobile) {
        return isMainPage.value;
      }

      return !store.state.isBlackFriday;
    },
    set: () => {},
  });

  const isAuthorized = computed(() => (!!store.state.isAuthorized));

  const isCookiePoliceAgree = computed(() => (!!store.state.user?.isCookiePoliceAgree));

  const checkCookiesAccepted = () => {
    const hasCookie = $cookies.get('COOKIES_ACCEPTED') === 'Y';

    // Если есть кука о согласии или пользователь авторизован
    // и у него есть согласие - устанавливаем куку и убираем попап
    if (hasCookie || (isAuthorized.value && isCookiePoliceAgree.value)) {
      $cookies.set('COOKIES_ACCEPTED', 'Y', getOptionsSetCookie($config.domainCookie));
      showCookies.value = false;

      // Если кука есть, но в данных пользователях нет согласия
      // - отправляем запрос с подтверждением согласия
      if (hasCookie && isAuthorized.value && !isCookiePoliceAgree.value) {
        user.confirmCookie();
      }

      return;
    }

    // Иначе стираем куку и показываем попап
    $cookies.remove('COOKIES_ACCEPTED', getOptionsSetCookie($config.domainCookie));
    showCookies.value = true;
  };

  const getThroughBanner = async () => {
    if (showBanner.value) {
      await throughBanner.getBanner({
        typeBanner: computeTypeBanner.value,
      });
    } else {
      throughBanner.removeBanner();
    }
  };

  watch(
    route,
    async () => {
      await getThroughBanner();
    },
    { immediate: false },
  );

  return {
    isAuthorized,
    showBanner,
    showFooter,
    showCookies,
    banner: throughBanner.banner,
    isLoadingBanner: throughBanner.isLoading,
    getThroughBanner,
    checkCookiesAccepted,
  };
};

export default useLayoutBlocks;
